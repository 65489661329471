<template>
    <div class="main-container">
      <h5 class="pageTitle" v-html="pageTitle"></h5>
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <!-- 검색 기준 선택 박스 -->
            <select v-model="searchCriteria" @change="updatePlaceholder" class="search-select">              
              <option value="MSG_NAME">템플릿명</option>
              <option value="MSG_CONTENT">템플릿내용</option>
            </select>
            <input 
              type="text" 
              v-model="search" 
              :placeholder="placeholder" 
              @input="filterMessages"
              class="search-input"
            />
            <button class="custom-btn-box btn-search" @click="searchButton">검색</button>
            <button class="custom-btn-box btn-register" @click="goToRegister">등록</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      
      <table>
        <thead>
          <tr>
            <th style="width: 5%;">No.</th>
            <th style="width: 30%;">템플릿명</th>
            <th style="width: 65%;">템플릿내용</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedData.length === 0">            
              <td colspan="3">데이터가 없습니다.</td>
          </tr>
          <!-- <tr v-for="(msg, index) in paginatedData" :key="index" :class="{'alt-row': index % 2 === 1}" @click="selectMessage(msg)">
            <td>{{ msg.MSG_ID }}</td>
            <td>{{ msg.MSG_NAME }}</td>
            <td>{{ msg.MSG_CONTENT }}</td>
          </tr> -->
          <tr 
            v-for="(msg, index) in paginatedData" 
            :key="msg.MSG_UUID" 
            :class="{'alt-row': index % 2 === 1}" 
            @click="selectMessage(msg)"
          >
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td> <!-- 순차번호 표시 -->
            <td>{{ msg.MSG_NAME }}</td>
            <td>{{ msg.MSG_CONTENT }}</td>
          </tr>
        </tbody>
      </table>
      
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ pageCount }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
      </div>
    
    </div>
  </template>
  
  <script>
  import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
  import axios from "@/axios";
  
  export default {
    components: {
      mdbContainer,   
      mdbRow,
      mdbCol
    },
    data() {
      return {
        pageTitle: '정규 메세지 조회',
        search: '',
        searchCriteria: 'MSG_NAME', // 검색 기준 (MSG_ID 또는 MSG_NAME)
        placeholder: '검색할 MSG_NAME을(를) 입력하세요.', // 초기 placeholder
        perPage: 10,
        filteredMessages: [],        
        messages: [], // 백엔드에서 가져온 데이터를 저장할 배열
        currentPage: 1, // currentPage를 data에 정의
        selectedMessage: null, // 선택된 메시지 정보 저장
      };
    },
    computed: {
        // paginatedData() {
        //     const start = (this.currentPage - 1) * this.perPage;
        //     const end = start + this.perPage;
        //     return this.filteredMessages.slice(start, end);
        // },
        paginatedData() {
          //const sortedData = this.filteredMessages.slice().sort((a, b) => a.MSG_ID - b.MSG_ID); // MSG_ID 기준 오름차순 정렬
          const start = (this.currentPage - 1) * this.perPage;
          const end = start + this.perPage;
          //return sortedData.slice(start, end);
          return this.filteredMessages.slice(start, end);
        },
        pageCount() {
            return Math.ceil(this.filteredMessages.length / this.perPage);
        },
    },
    watch: {
        search() {
            this.currentPage = 1; // 검색 시 항상 첫 페이지로 이동
            this.filterMessages(); // 검색 결과 필터링
        }
    },
    methods: {
        goToRegister() {
          // 등록 페이지로 이동하는 로직 추가
          this.$router.push({ name: 'formedsms-create' }); 
        },
        async fetchMessages() {
          const tokenData = JSON.parse(sessionStorage.getItem('token'));
          const token = tokenData ? tokenData.access_token : '';

          //console.log(sessionStorage.getItem('token'));
            const config = {
                method: 'get',
                url: `/sms-template/read_formed_messages`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };
            try {
                const response = await axios(config);
                this.messages = response.data
                .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) // ← 여기에 updatedAt 있어야 가능
                .map(item => ({
                  MSG_UUID: item.uuid,
                  MSG_ID: item.msg_id,
                  MSG_NAME: item.msg_name,
                  MSG_CONTENT: item.msg_contents
                }));
                
                console.log("Sorted messages:", this.messages); // 정렬된 데이터 확인
                this.filteredMessages = this.messages; // 초기 필터링 데이터 설정
            } catch (error) {
                console.error('메시지 가져오기 오류:', error);
            }
        },

        updatePlaceholder() {
            this.placeholder = `검색할 ${this.searchCriteria === 'MSG_NAME' ? '템플릿명' : '템플릿내용'}을(를) 입력하세요.`;
        },

        filterMessages() {
            if (this.search.trim() === '') {
            this.filteredMessages = this.messages;
            } else {
            this.filteredMessages = this.messages.filter(message => {
                const searchField = this.searchCriteria === 'MSG_NAME' ? message.MSG_NAME : message.MSG_CONTENT;
                return searchField.toLowerCase().includes(this.search.toLowerCase());
            });
            }
        },

        searchButton() {
            this.currentPage = 1; // 페이지 초기화
            this.filterMessages(); // 검색 결과 필터링
        },

        prevPage() {
            if (this.currentPage > 1) {
            this.currentPage -= 1; // 이전 페이지
            }
        },

        nextPage() {
            if (this.currentPage < this.pageCount) {       
            this.currentPage += 1; // 다음 페이지
            }
        },
        // 행 클릭 시 호출되는 함수
        selectMessage(msg) {
          // msg 객체를 JSON 형태로 쿼리로 전달
          //console.log("Selected message data:", msg); // msg 객체의 데이터를 확인
          this.$router.push({ 
            name: 'formedsms-UpdateDelete', 
            query: {
              uuid: msg.MSG_UUID,      // 여기에서 올바른 필드 이름 사용
              msgId: msg.MSG_ID,
              msgName: msg.MSG_NAME,
              msgContent: msg.MSG_CONTENT
            }
          });
        }
 
    },
    
    mounted() {
      this.fetchMessages();
      this.updatePlaceholder(); // 초기화 시 placeholder 설정
    }
  }
  </script>
  
  
  <style scoped>
  .main-container {
    padding: 15px;
  }
  
  .pageTitle {
    font-weight: bold;
  }
  
  .search-container {
    padding: 25px;
 
  }
  
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 오른쪽 정렬 */
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px; /* 각 요소 사이에 간격 추가 */
  }
  
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-right: 20px; /* 검색 버튼과 등록 버튼 사이의 여백 */
    /*cursor: pointer;
     transition: background-color 0.3s ease; */
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-left: auto; /* 등록 버튼을 오른쪽 끝으로 정렬 */
    align-self: flex-end; /* 등록 버튼을 아래쪽으로 정렬 */
    }
  /* .btn-search {
  margin-top: 23px; 
  background-color: #e6f2ff;
  padding: 11px 12px;
  font-size: 14px;
  border: 0;
  width: 67px;
  height: 38px;
  line-height: 1rem;
} */
  
  /* .btn-search:hover {
    background-color: #45a049;
  } */
  
  table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;  
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  
  .alt-row {
    background-color: #f9f9f9; 
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #45a049;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  
  .pagination span {
    margin: 0 10px;
    line-height: 1.5;
  } 
  
  tr:hover {
    background-color: #e7e3e3;  
  }
  </style>
  